import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { exampleReducer } from './example/example.reducer';

export const store = configureStore({
  reducer: {
    example: exampleReducer,
  },
  middleware(gDM) {
    return gDM().concat(thunk);
  },
  // Depending on the environment, we could also use process.env.NODE_ENV === 'development'
  devTools: process.env.NODE_ENV !== 'production',
});
