import { ThunkDispatch } from 'redux-thunk';
import { ExampleService } from '../../services/example.service';
import { ActionTypes } from '../constants/action.types';
import { RootState } from '../types';
import { IExampleAction } from './types';

const exampleService = new ExampleService();

export const getWordsAction = Object.assign(
  () => async (dispatch: ThunkDispatch<RootState, void, IExampleAction>) => {
    dispatch(getWordsAction.start());
    try {
      const words = await exampleService.getHeroWords();
      dispatch(getWordsAction.success(words));
    } catch (error) {
      const errorMessage =
        (error as Error)?.message ??
        (error as string) ??
        'Something went wrong';
      dispatch(getWordsAction.fail(errorMessage));
    }
  },
  {
    start: (): IExampleAction => ({ type: ActionTypes.GET_WORDS_START }),
    success: (words: string[]): IExampleAction => ({
      type: ActionTypes.GET_WORDS_SUCCESS,
      words,
    }),
    fail: (error: string): IExampleAction => ({
      type: ActionTypes.GET_WORDS_FAIL,
      error,
    }),
  }
);
