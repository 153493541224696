import { useState } from 'react';
import Isurvey from '../../utils/surveyTypes';
import checkIcon from '../../assets/imgs/check.png';
import closeIcon from '../../assets/imgs/close.png';
import Header from '../Header/Header';
import classes from './SurveyQuestion.module.css';

interface SurveyQuestionProps {
  question: any;
  onChange: (step: string) => void;
  answer: string | null | undefined;
  setAnswer: any;
  isRedDot?: boolean;
}
const defaultProps = {
  isRedDot: false,
};
const SurveyQuestion = ({
  question,
  onChange,
  answer,
  setAnswer,
  isRedDot,
}: SurveyQuestionProps) => {
  const [submitted, setSubmitted] = useState(false);
  return (
    <div className={classes.container}>
      <Header isRedDot={isRedDot} />
      <div
        className={`${classes.questionContainer} ${
          !question.answer5 && classes.questionContainerSmall
        }`}
      >
        <h3 className={classes.question}>{question.question}</h3>
        <div className={classes.answerList}>
          {/* First Label */}
          <label
            className={`${classes.label} ${
              question.answer1 === question.correctAnswer &&
              submitted &&
              classes.correctAnswer
            }`}
            htmlFor={question.answer1}
          >
            <input
              className={classes.radioButton}
              disabled={submitted}
              type="radio"
              id={question.answer1}
              name={question.name}
              value={question.answer1}
              onClick={() =>
                setAnswer((prev: Isurvey) => {
                  return { ...prev, [question.name]: question.answer1 };
                })
              }
            />
            {submitted &&
              answer === question.answer1 &&
              question.correctAnswer !== question.answer1 && (
                <img
                  className={classes.closeIcon}
                  src={closeIcon}
                  alt="closeIcon"
                />
              )}
            {submitted &&
              answer === question.answer1 &&
              question.correctAnswer === question.answer1 && (
                <img
                  className={classes.checkIcon}
                  src={checkIcon}
                  alt="checkIcon"
                />
              )}
            <i
              className={`${
                answer === question.answer1
                  ? classes.medioCorrect
                  : classes.media
              } ${
                submitted && question.correctAnswer !== question.answer1
                  ? classes.mediaNotCorrect
                  : classes.media
              }`}
            >
              A. {question.answer1}
            </i>
          </label>
          {/* Second Label */}
          <label
            className={`${classes.label} ${
              question.answer2 === question.correctAnswer &&
              submitted &&
              classes.correctAnswer
            }`}
            htmlFor={question.answer2}
          >
            {submitted &&
              answer === question.answer2 &&
              question.correctAnswer !== question.answer2 && (
                <img
                  className={classes.closeIcon}
                  src={closeIcon}
                  alt="closeIcon"
                />
              )}
            {submitted &&
              answer === question.answer2 &&
              question.correctAnswer === question.answer2 && (
                <img
                  className={classes.checkIcon}
                  src={checkIcon}
                  alt="checkIcon"
                />
              )}
            <input
              className={classes.radioButton}
              disabled={submitted}
              type="radio"
              id={question.answer2}
              name={question.name}
              value={question.answer2}
              onClick={() =>
                setAnswer((prev: Isurvey) => {
                  return { ...prev, [question.name]: question.answer2 };
                })
              }
            />
            <i
              className={`${
                answer === question.answer2
                  ? classes.medioCorrect
                  : classes.media
              } ${
                submitted && question.correctAnswer !== question.answer2
                  ? classes.mediaNotCorrect
                  : classes.media
              }`}
            >
              B. {question.answer2}
            </i>
          </label>
          {/* Third Label */}
          <label
            className={` ${classes.label} ${
              question.answer3 === question.correctAnswer &&
              submitted &&
              classes.correctAnswer
            }`}
            htmlFor={question.answer3}
          >
            <input
              className={classes.radioButton}
              disabled={submitted}
              type="radio"
              id={question.answer3}
              name={question.name}
              value={question.answer3}
              onClick={() =>
                setAnswer((prev: Isurvey) => {
                  return { ...prev, [question.name]: question.answer3 };
                })
              }
            />
            <i
              className={`${
                answer === question.answer3
                  ? classes.medioCorrect
                  : classes.media
              } ${
                submitted && question.correctAnswer !== question.answer3
                  ? classes.mediaNotCorrect
                  : classes.media
              }`}
            >
              C. {question.answer3}
            </i>
            {submitted &&
              answer === question.answer3 &&
              question.correctAnswer !== question.answer3 && (
                <img
                  className={classes.closeIcon}
                  src={closeIcon}
                  alt="closeIcon"
                />
              )}
            {submitted &&
              answer === question.answer3 &&
              question.correctAnswer === question.answer3 && (
                <img
                  className={classes.checkIcon}
                  src={checkIcon}
                  alt="checkIcon"
                />
              )}
          </label>
          {/* Fourth Label */}
          <label
            className={`${classes.label} ${
              question.answer4 === question.correctAnswer &&
              submitted &&
              classes.correctAnswer
            }`}
            htmlFor={question.answer4}
          >
            <input
              className={classes.radioButton}
              disabled={submitted}
              type="radio"
              id={question.answer4}
              name={question.name}
              value={question.answer4}
              onClick={() =>
                setAnswer((prev: Isurvey) => {
                  return { ...prev, [question.name]: question.answer4 };
                })
              }
            />
            <i
              className={`${
                answer === question.answer4
                  ? classes.medioCorrect
                  : classes.media
              } ${
                submitted && question.correctAnswer !== question.answer4
                  ? classes.mediaNotCorrect
                  : classes.media
              }`}
            >
              D. {question.answer4}
            </i>
            {submitted &&
              answer === question.answer4 &&
              question.correctAnswer !== question.answer4 && (
                <img
                  className={classes.closeIcon}
                  src={closeIcon}
                  alt="closeIcon"
                />
              )}
            {submitted &&
              answer === question.answer4 &&
              question.correctAnswer === question.answer4 && (
                <img
                  className={classes.checkIcon}
                  src={checkIcon}
                  alt="checkIcon"
                />
              )}
          </label>
          {/* Fifth Label */}
          {question.answer5 && (
            <label
              className={`${classes.label} ${
                question.answer5 === question.correctAnswer &&
                submitted &&
                classes.correctAnswer
              }`}
              htmlFor={question.answer5}
            >
              <input
                className={classes.radioButton}
                disabled={submitted}
                type="radio"
                id={question.answer5}
                name={question.name}
                value={question.answer5}
                onClick={() =>
                  setAnswer((prev: Isurvey) => {
                    return { ...prev, [question.name]: question.answer5 };
                  })
                }
              />
              <i
                className={`${
                  answer === question.answer5
                    ? classes.medioCorrect
                    : classes.media
                } ${
                  submitted && question.correctAnswer !== question.answer5
                    ? classes.mediaNotCorrect
                    : classes.media
                }`}
              >
                E. {question.answer5}
              </i>
              {submitted &&
                answer === question.answer5 &&
                question.correctAnswer !== question.answer5 && (
                  <img
                    className={classes.closeIcon}
                    src={closeIcon}
                    alt="closeIcon"
                  />
                )}
              {submitted &&
                answer === question.answer5 &&
                question.correctAnswer === question.answer5 && (
                  <img
                    className={classes.checkIcon}
                    src={checkIcon}
                    alt="checkIcon"
                  />
                )}
            </label>
          )}
        </div>
      </div>
      <p className={classes.trademark}>{question.reference}</p>
      <button
        type="button"
        className={classes.button}
        onClick={() => {
          if (submitted) {
            onChange(question.name);
            setSubmitted(false);
          } else {
            setSubmitted(true);
          }
        }}
        disabled={answer === null || answer === undefined}
      >
        {!submitted ? (
          <p className={classes.buttonText}>Submit</p>
        ) : (
          <p>Continue</p>
        )}
      </button>
    </div>
  );
};

SurveyQuestion.defaultProps = defaultProps;

export default SurveyQuestion;
