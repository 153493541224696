// import { useState } from 'react';
import { Geography } from 'react-simple-maps';

const GeographyContainer = ({ geo, onPress, region }: any) => {
  return (
    <Geography
      key={geo.rsmKey}
      geography={geo}
      onClick={() => onPress(geo.properties.continent)}
      style={{
        default: {
          fill: region.includes(geo.properties.continent)
            ? '#e93237'
            : '#d0d2d3',
          outline: 'none',
        },
        hover: {
          fill: region.includes(geo.properties.continent)
            ? '#e93237'
            : '#d0d2d3',
          outline: 'none',
        },
        pressed: {
          fill: '#e93237',
          outline: 'none',
        },
      }}
    />
  );
};

export default GeographyContainer;
