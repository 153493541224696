import Title from '../../assets/imgs/smallTitle.png';
import Logo from '../../assets/imgs/logo.svg';
import classes from './Header.module.css';

interface HeaderProps {
  isRedDot?: boolean;
}
const defaultProps = {
  isRedDot: false,
};

const Header = ({ isRedDot }: HeaderProps) => {
  return (
    <div className={classes.container}>
      <div className={classes.leftHeader}>
        <img className={classes.title} src={Title} alt="title" />
        {isRedDot && <div className={classes.redDot} />}
      </div>
      <img className={classes.logo} src={Logo} alt="logo" />
    </div>
  );
};

Header.defaultProps = defaultProps;

export default Header;
