/* eslint-disable jsx-a11y/control-has-associated-label */

import Header from '../Header/Header';
import classes from './SurveyYear.module.css';

interface SurveyYearProps {
  onChange: (step: string) => void;
  answer: string | null | undefined;
  setAnswer: any;
}

const SurveyYear = ({ onChange, answer, setAnswer }: SurveyYearProps) => {
  return (
    <div className={classes.container}>
      <Header />
      <p className={classes.content}>
        In a year, how many of your patients travel outside of the United
        States?
      </p>
      <div className={classes.circlesContainer}>
        <div className={classes.firstLine}>
          <div
            role="button"
            onClick={() =>
              setAnswer((prev: any) => {
                return { ...prev, patients: '0' };
              })
            }
            onKeyDown={() =>
              setAnswer((prev: any) => {
                return { ...prev, patients: '0' };
              })
            }
            tabIndex={0}
            className={answer === '0' ? classes.checkCircle : classes.circle}
            style={{ left: '10%' }}
          />
          <div
            role="button"
            onClick={() =>
              setAnswer((prev: any) => {
                return { ...prev, patients: 'fewer than 5' };
              })
            }
            onKeyDown={() =>
              setAnswer((prev: any) => {
                return { ...prev, patients: 'fewer than 5' };
              })
            }
            tabIndex={-1}
            className={
              answer === 'fewer than 5' ? classes.checkCircle : classes.circle
            }
            style={{ left: '30%' }}
          />
          <div
            role="button"
            onClick={() =>
              setAnswer((prev: any) => {
                return { ...prev, patients: '5 - 10' };
              })
            }
            onKeyDown={() =>
              setAnswer((prev: any) => {
                return { ...prev, patients: '5 - 10' };
              })
            }
            tabIndex={-2}
            className={
              answer === '5 - 10' ? classes.checkCircle : classes.circle
            }
            style={{ left: '50%' }}
          />
          <div
            role="button"
            onClick={() =>
              setAnswer((prev: any) => {
                return { ...prev, patients: '11 - 20' };
              })
            }
            onKeyDown={() =>
              setAnswer((prev: any) => {
                return { ...prev, patients: '11 - 20' };
              })
            }
            tabIndex={-3}
            className={
              answer === '11 - 20' ? classes.checkCircle : classes.circle
            }
            style={{ left: '70%' }}
          />
          <div
            role="button"
            onClick={() =>
              setAnswer((prev: any) => {
                return { ...prev, patients: 'more than 20' };
              })
            }
            onKeyDown={() =>
              setAnswer((prev: any) => {
                return { ...prev, patients: 'more than 20' };
              })
            }
            tabIndex={-4}
            className={
              answer === 'more than 20' ? classes.checkCircle : classes.circle
            }
            style={{ left: '90%' }}
          />
        </div>
        <div className={classes.patientsQuantity}>
          <span className={classes.patients} style={{ left: '10%' }}>
            0
          </span>
          <span className={classes.patients} style={{ left: '30%' }}>
            Fewer Than 5
          </span>
          <span className={classes.patients} style={{ left: '50%' }}>
            5 - 10
          </span>
          <span className={classes.patients} style={{ left: '70%' }}>
            11 - 20
          </span>
          <span className={classes.patients} style={{ left: '90%' }}>
            More Than 20
          </span>
        </div>
      </div>
      <button
        type="button"
        className={classes.button}
        onClick={() => onChange('surveyYear')}
        disabled={answer === null}
      >
        Continue
      </button>
    </div>
  );
};

export default SurveyYear;
