/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getInformation = /* GraphQL */ `
  query GetInformation($id: ID!) {
    getInformation(id: $id) {
      id
      patients
      disease
      infections
      temperatures
      arrivalsToUsa
      endemic
      regions
      firstname
      lastname
      radio
      affiliation
      email
      createdAt
      updatedAt
    }
  }
`;
export const listInformation = /* GraphQL */ `
  query ListInformation(
    $filter: ModelInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInformation(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patients
        disease
        infections
        temperatures
        arrivalsToUsa
        endemic
        regions
        firstname
        lastname
        radio
        affiliation
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
