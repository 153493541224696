import { FC, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { useAppDispatch } from './hooks/redux';
import { getWordsAction } from './store/example/example.actions';
import { Router } from './pages/Router/router';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const App: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getWordsAction());
  }, []);

  return <Router />;
};

export default App;
