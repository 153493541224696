export const questions = [
  {
    question:
      '___________ is the fastest-spreading vector-borne viral disease worldwide.',
    answer1: 'Malaria',
    answer2: 'Dengue',
    answer3: 'West Nile virus',
    answer4: 'COVID 19',
    correctAnswer: 'Dengue',
    name: 'disease',
    nextStep: '4',
    reference:
      'REFERENCE: OutbreakObservatory. The global rise of dengue infections. Available at: https://www.outbreakobservatory.org/outbreakthursday-1/3/21/2019/the-global-rise-of-dengue-infections. Accessed March 2020.',
  },
  {
    question:
      'The incidence of dengue has grown dramatically around the world in recent decades, causing an estimated _____ infections annually.',
    answer1: '19 million',
    answer2: '90 million',
    answer3: '190 million',
    answer4: '390 million',
    correctAnswer: '390 million',
    name: 'infections',
    nextStep: '5',
    reference:
      'REFERENCE: World Health Organization. Dengue and Severe Dengue. https://www.who.int/en/news-room/fact-sheets/detail/dengue-and severe-dengue. Published June 23, 2020.',
  },
  {
    question:
      'Climate change is predicted to enhance distribution of the Dengue vector to the following regions:',
    answer1: 'Eastern North America',
    answer2: 'East Asia',
    answer3: 'Northern and Eastern Australia',
    answer4: 'Southern Europe',
    answer5: 'All of the above',
    correctAnswer: 'All of the above',
    name: 'temperatures',
    nextStep: '6',
    reference:
      'REFERENCE: Ebi, K. L., & Nealon, J. (2016). Dengue in a changing climate. Environmental Research, 151(151), 115–123. https://doi.org/10.1016/j.envres.2016.07.026',
  },
  {
    question:
      '___________ is a leading cause of fever among travelers returning from Latin America, the Caribbean and Southeast Asia.',
    answer1: 'Malaria',
    answer2: 'COVID-19',
    answer3: 'Dengue',
    answer4: 'None of the above',
    correctAnswer: 'Dengue',
    name: 'arrivalsToUsa',
    nextStep: '7',
    reference:
      'REFERENCE: CDC. Travelers’ Health, 2020 Yellow Book Chapter 4. Dengue. https://wwwnc.cdc.gov/travel/yellowbook/2020/travel-related-infectious-diseases/dengue',
  },
  {
    question:
      'In 1970, Dengue was endemic in 9 countries. Since 1970, Dengue has become endemic in _____________.',
    answer1: '39 countries',
    answer2: '59 countries',
    answer3: '75 countries',
    answer4: 'Over 100 countries',
    correctAnswer: 'Over 100 countries',
    name: 'endemic',
    nextStep: '8',
    reference:
      'REFERENCE: World Health Organization. Dengue and Severe Dengue. https://www.who.int/en/news-room/fact-sheets/detail/dengue-and severe-dengue. Published June 23, 2020.',
  },
];
