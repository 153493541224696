import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import Header from '../Header/Header';
import classes from './ThankYou.module.css';

interface ThankYouProps {
  onChange: (step: string) => void;
  setSteps: (number: number) => void;
  setSurvey: (initialValues: any) => void;
  initialValues: any;
}

const ThankYou = ({
  onChange,
  setSteps,
  setSurvey,
  initialValues,
}: ThankYouProps) => {
  const [stopActivity, setStopActivity] = useState(false);

  const handleActivity = () => {
    setStopActivity(true);
  };
  useIdleTimer({
    timeout: 1100000,
    onIdle: handleActivity,
    debounce: 500,
  });

  useEffect(() => {
    if (stopActivity) {
      setSteps(0);
      setStopActivity(false);
      setSurvey(initialValues);
    }
  }, [stopActivity]);
  return (
    <div
      role="button"
      onClick={() => onChange('thankyou')}
      onKeyDown={() => onChange('thankyou')}
      tabIndex={0}
      className={classes.container}
    >
      <Header />
      <h2 className={classes.title}>Thank You</h2>
      <h3 className={classes.title2}>for sharing your insights</h3>
      <h4 className={classes.copyright}>
        ©2022 Takeda Pharmaceuticals U.S.A., Inc. All rights reserved.
        VV-MEDMAT-76349 10/22
      </h4>
      <div className={classes.footer}>
        <p className={classes.footerText}>Done</p>
      </div>
    </div>
  );
};

export default ThankYou;
