import { updateObject } from '../../utils';
import { ActionTypes } from '../constants/action.types';
import { IExampleAction, IExampleState } from './types';

export const initialState: IExampleState = {
  loading: false,
};

export const exampleReducer = (
  state = initialState,
  action?: IExampleAction
) => {
  switch (action?.type) {
    case ActionTypes.GET_WORDS_START:
      return updateObject(state, { loading: true });
    case ActionTypes.GET_WORDS_SUCCESS:
      return updateObject(state, { loading: false, words: action.words });
    case ActionTypes.GET_WORDS_FAIL:
      return updateObject(state, { loading: false, error: action.error });
    default:
      return state;
  }
};
