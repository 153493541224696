import Title from '../../assets/imgs/title.png';
import Logo from '../../assets/imgs/logo.svg';
import classes from './Main.module.css';

interface MainProps {
  onChange: (step: string) => void;
}

const Main = ({ onChange }: MainProps) => {
  return (
    <div
      role="button"
      onClick={() => onChange('main')}
      onKeyDown={() => onChange('main')}
      tabIndex={0}
      className={classes.Container}
    >
      <img className={classes.logo} src={Logo} alt="logo" />
      <img className={classes.title} src={Title} alt="title" />
      <p className={classes.content}>
        Please answer a few questions to determine your familiarity with the
        Dengue disease state.
      </p>
      <div className={classes.footer}>
        <p className={classes.footerText}>Touch screen to begin</p>
      </div>
    </div>
  );
};

export default Main;
