import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { getInfo } from '../../services/createInfo';
import classes from './Metrics.module.css';

const Metrics = () => {
  const [info, setInfo] = useState<any>([]);
  useEffect(() => {
    getInfo()
      .then((data: any) => {
        const sortedData = data.sort(function (a: any, b: any) {
          return Number(a.createdAt) - Number(b.createdAt);
        });
        return sortedData;
      })
      .then((parseData: any) => {
        setInfo(parseData);
      });
  }, []);

  return (
    <div className={classes.container}>
      <CSVLink data={info}>
        <button type="button" className={classes.button}>
          Download CSV
        </button>
      </CSVLink>
    </div>
  );
};

export default Metrics;
