import { FC, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import SurveyYear from '../../components/SurveyYear/SurveyYear';
import Main from '../../components/Main/Main';
import classes from './Home.module.css';
import Isurvey from '../../utils/surveyTypes';
import Map from '../../components/Map/Map';
import SurveyQuestion from '../../components/SurveyQuestion/SurveyQuestion';
import { questions } from '../../utils/surveyQuestions';
import ThankYou from '../../components/ThankYou/ThankYou';
import UserInfo from '../../components/UserInfo/UserInfo';
import { postInformation } from '../../services/createInfo';

const initialValue = {
  patients: null,
  disease: null,
  infections: null,
  temperatures: null,
  arrivalsToUsa: null,
  endemic: null,
  regions: [],
  firstname: '',
  lastname: '',
  radio: '',
  affiliation: '',
  email: '',
};
const TIMEOUT_ACTIVITY = 15000;

const Home: FC = () => {
  const [steps, setSteps] = useState(0);
  const [survey, setSurvey] = useState<Isurvey>(initialValue);

  const changeSteps = (step: string) => {
    switch (step) {
      case 'main':
        setSteps(1);
        break;
      case 'surveyYear':
        setSteps(2);
        break;
      case 'map':
        setSteps(3);
        break;
      case 'disease':
        setSteps(4);
        break;
      case 'infections':
        setSteps(5);
        break;
      case 'temperatures':
        setSteps(6);
        break;
      case 'arrivalsToUsa':
        setSteps(7);
        break;
      case 'endemic':
        setSteps(8);
        break;
      case 'user':
        setSteps(9);
        break;
      case 'thankyou':
        setSteps(0);
        setSurvey(initialValue);
        break;
      default:
    }
  };
  const validateRedDot = () => {
    if (
      survey.patients === '5 - 10' ||
      survey.patients === '11 - 20' ||
      survey.patients === 'more than 20'
    ) {
      if (
        survey.regions?.includes('Africa') ||
        survey.regions?.includes('South America') ||
        survey.regions?.includes('Asia') ||
        survey.regions?.includes('Mexico')
      ) {
        return true;
      }
    }
    return false;
  };

  const [stopActivity, setStopActivity] = useState(false);
  const [isShown, setIsShown] = useState(false);

  const onConfirm = () => {
    setIsShown(!isShown);
  };

  const handleActivity = () => {
    if (steps === 0 || steps === 9) return;
    setIsShown(!isShown);
  };

  useIdleTimer({
    timeout: 30000,
    onIdle: handleActivity,
    debounce: 500,
  });

  useEffect(() => {
    let timeout: any;

    if (isShown && !stopActivity) {
      timeout = setTimeout(() => {
        setStopActivity(true);
        clearTimeout(timeout);
      }, TIMEOUT_ACTIVITY);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isShown, stopActivity]);

  useEffect(() => {
    if (stopActivity) {
      postInformation(survey);
      setSurvey(initialValue);
      setSteps(0);
      setIsShown(false);
      setStopActivity(false);
    }
  }, [stopActivity]);

  return (
    <div className={classes.container}>
      {isShown && (
        <div className={classes.modal}>
          <div className={classes.modalContent}>
            <p className={classes.modalQuestion}>Do you wish to continue?</p>
            <button
              className={classes.modalButton}
              type="button"
              onClick={onConfirm}
            >
              <p className={classes.buttonText}>Continue</p>
            </button>
          </div>
        </div>
      )}
      {steps === 0 && <Main onChange={changeSteps} />}
      {steps === 1 && (
        <SurveyYear
          onChange={changeSteps}
          answer={survey.patients}
          setAnswer={setSurvey}
        />
      )}
      {steps === 2 && (
        <Map
          onChange={changeSteps}
          answer={survey.regions}
          setAnswer={setSurvey}
        />
      )}
      {steps === 3 && (
        <SurveyQuestion
          question={questions[0]}
          onChange={changeSteps}
          answer={survey.disease}
          setAnswer={setSurvey}
          isRedDot={validateRedDot()}
        />
      )}
      {steps === 4 && (
        <SurveyQuestion
          question={questions[1]}
          onChange={changeSteps}
          answer={survey.infections}
          setAnswer={setSurvey}
          isRedDot={validateRedDot()}
        />
      )}
      {steps === 5 && (
        <SurveyQuestion
          question={questions[2]}
          onChange={changeSteps}
          answer={survey.temperatures}
          setAnswer={setSurvey}
          isRedDot={validateRedDot()}
        />
      )}
      {steps === 6 && (
        <SurveyQuestion
          question={questions[3]}
          onChange={changeSteps}
          answer={survey.arrivalsToUsa}
          setAnswer={setSurvey}
          isRedDot={validateRedDot()}
        />
      )}
      {steps === 7 && (
        <SurveyQuestion
          question={questions[4]}
          onChange={changeSteps}
          answer={survey.endemic}
          setAnswer={setSurvey}
          isRedDot={validateRedDot()}
        />
      )}
      {steps === 8 && (
        <UserInfo
          onChange={changeSteps}
          setAnswer={setSurvey}
          survey={survey}
        />
      )}

      {steps === 9 && (
        <ThankYou
          onChange={changeSteps}
          setSteps={setSteps}
          setSurvey={setSurvey}
          initialValues={initialValue}
        />
      )}
    </div>
  );
};

export default Home;
