import { API, graphqlOperation } from 'aws-amplify';
import { createInformation } from '../graphql/mutations';
import { listInformation } from '../graphql/queries';

export const postInformation = async (info: any) => {
  try {
    const infoReq: { data?: any; errors?: any } = await API.graphql(
      graphqlOperation(createInformation, { input: info })
    );

    if (infoReq.errors) {
      return { status: 400 };
    }
    return { status: 200 };
  } catch {
    return { status: 500 };
  }
};

export const getInfo: any = async (
  request = { items: [], nextToken: null, first: true }
) => {
  if (!request.nextToken && !request.first) {
    return request.items;
  }
  const information: { data?: any } = await API.graphql({
    query: listInformation,
    variables: {
      nextToken: request.nextToken,
    },
  });
  return getInfo({
    items: [...request.items, ...information.data.listInformation.items],
    nextToken: information.data.listInformation.nextToken,
    first: false,
  });
};
