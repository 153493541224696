import { useCallback, useState } from 'react';
import { postInformation } from '../../services/createInfo';
import Isurvey from '../../utils/surveyTypes';
import Header from '../Header/Header';
import classes from './UserInfo.module.css';

interface UserInfoProps {
  onChange: (step: string) => void;
  setAnswer: any;
  survey: Isurvey;
}

const initialValues = {
  firstname: '',
  lastname: '',
  radio: '',
  affiliation: '',
  email: '',
};

const UserInfo = ({ onChange, setAnswer, survey }: UserInfoProps) => {
  const [form, setForm] = useState<any>(initialValues);
  const onSubmit = useCallback(
    (e: any) => {
      setAnswer((prev: any) => {
        return {
          ...prev,
          firstname: form.firstname,
          lastname: form.lastname,
          radio: form.radio,
          affiliation: form.affiliation,
          email: form.email,
        };
      });
      postInformation({
        ...survey,
        firstname: form.firstname,
        lastname: form.lastname,
        radio: form.radio,
        affiliation: form.affiliation,
        email: form.email,
      });
      e.preventDefault();
      onChange('user');
    },
    [form]
  );

  return (
    <div className={classes.container}>
      <Header />
      <form className={classes.userForm} onSubmit={(e) => onSubmit(e)}>
        <div className={classes.username}>
          <input
            className={classes.userInput}
            type="text"
            placeholder="First Name"
            value={form.firstname}
            onChange={(e: any) =>
              setForm((prev: any) => ({ ...prev, firstname: e.target.value }))
            }
          />
          <input
            className={classes.userInput}
            type="text"
            placeholder="Last Name"
            value={form.lastname}
            onChange={(e: any) =>
              setForm((prev: any) => ({ ...prev, lastname: e.target.value }))
            }
          />
        </div>
        <div className={classes.radioForm}>
          <label className={classes.label} htmlFor="us">
            <input
              className={classes.input}
              type="radio"
              name="radio"
              id="us"
              value="us_hcp"
              onClick={(e: any) =>
                setForm((prev: any) => ({ ...prev, radio: e.target.value }))
              }
            />
            {'  '}US HCP
          </label>
          <label
            className={`${classes.label} ${classes.marginLabel}`}
            htmlFor="research"
          >
            <input
              className={classes.input}
              type="radio"
              name="radio"
              id="research"
              value="researcher"
              onClick={(e: any) =>
                setForm((prev: any) => ({ ...prev, radio: e.target.value }))
              }
            />
            {'  '}Researcher
          </label>
          <label
            className={`${classes.label} ${classes.marginLabel}`}
            htmlFor="other"
          >
            <input
              className={classes.input}
              type="radio"
              name="radio"
              id="other"
              value="other"
              onClick={(e: any) =>
                setForm((prev: any) => ({ ...prev, radio: e.target.value }))
              }
            />
            {'  '}Other
          </label>
        </div>
        <input
          className={classes.affiliation}
          type="text"
          placeholder="Affiliation"
          value={form.affiliation}
          onChange={(e: any) =>
            setForm((prev: any) => ({ ...prev, affiliation: e.target.value }))
          }
        />
        <div className={classes.submitForm}>
          <input
            className={classes.emailInput}
            type="email"
            placeholder="Email Address"
            value={form.email}
            onChange={(e: any) =>
              setForm((prev: any) => ({ ...prev, email: e.target.value }))
            }
          />
          <button className={classes.submitButton} type="submit">
            <p className={classes.buttonText}>Submit</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserInfo;
