/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createInformation = /* GraphQL */ `
  mutation CreateInformation(
    $input: CreateInformationInput!
    $condition: ModelInformationConditionInput
  ) {
    createInformation(input: $input, condition: $condition) {
      id
      patients
      disease
      infections
      temperatures
      arrivalsToUsa
      endemic
      regions
      firstname
      lastname
      radio
      affiliation
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateInformation = /* GraphQL */ `
  mutation UpdateInformation(
    $input: UpdateInformationInput!
    $condition: ModelInformationConditionInput
  ) {
    updateInformation(input: $input, condition: $condition) {
      id
      patients
      disease
      infections
      temperatures
      arrivalsToUsa
      endemic
      regions
      firstname
      lastname
      radio
      affiliation
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteInformation = /* GraphQL */ `
  mutation DeleteInformation(
    $input: DeleteInformationInput!
    $condition: ModelInformationConditionInput
  ) {
    deleteInformation(input: $input, condition: $condition) {
      id
      patients
      disease
      infections
      temperatures
      arrivalsToUsa
      endemic
      regions
      firstname
      lastname
      radio
      affiliation
      email
      createdAt
      updatedAt
    }
  }
`;
