// import { useEffect, useState } from 'react';
// import { useState } from 'react';
import { ComposableMap, Geographies, Marker } from 'react-simple-maps';
import GeographyContainer from '../GeographyContainer/GeographyContainer';
import Header from '../Header/Header';
import classes from './Map.module.css';

interface MapProps {
  onChange: (step: string) => void;
  answer: string[] | undefined;
  setAnswer: any;
}

const Map = ({ onChange, answer, setAnswer }: MapProps) => {
  // const [region, setRegion] = useState<string[]>([]);
  const onPress = (geog: any) => {
    if (answer === undefined) return;
    if (answer.includes(geog)) {
      const tempRegion = answer.filter((el: string) => el !== geog);
      setAnswer((prev: any) => {
        return { ...prev, regions: tempRegion };
      });
    } else {
      setAnswer((prev: any) => {
        return { ...prev, regions: [...prev.regions, geog] };
      });
    }
  };
  return (
    <div className={classes.container}>
      <Header />
      <h3 className={classes.title}>
        Which regions of the world do your patients travel to?
      </h3>
      <ComposableMap
        width={500}
        height={250}
        projection="geoMercator"
        projectionConfig={{
          center: [25, 30],
          scale: 60,
        }}
      >
        <Geographies geography="/combine.geojson">
          {({ geographies }) =>
            geographies.map((geo) => (
              <GeographyContainer
                key={geo.rsmKey}
                geo={geo}
                onPress={onPress}
                region={answer}
              />
            ))
          }
        </Geographies>

        {/* Canada */}

        <Marker coordinates={[-110, 60]} fill="#777">
          <g
            fill="none"
            stroke={answer && answer.includes('Canada') ? 'white' : '#FF5533'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="scale(0.5)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
        </Marker>

        {/* Usa */}

        <Marker coordinates={[-105, 45]} fill="#777">
          <g
            fill="none"
            stroke={
              answer && answer.includes('United States') ? 'white' : '#FF5533'
            }
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="scale(0.5)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
        </Marker>

        {/* Mexico */}

        <Marker coordinates={[-107, 30]} fill="#777">
          <g
            fill="none"
            stroke={answer && answer.includes('Mexico') ? 'white' : '#FF5533'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="scale(0.5)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
        </Marker>

        {/* Africa */}

        <Marker coordinates={[20, 10]} fill="#777">
          <g
            fill="none"
            stroke={answer && answer.includes('Africa') ? 'white' : '#FF5533'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="scale(0.5)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
        </Marker>

        {/* South America */}

        <Marker coordinates={[-60, -10]} fill="#777">
          <g
            fill="none"
            stroke={
              answer && answer.includes('South America') ? 'white' : '#FF5533'
            }
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="scale(0.5)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
        </Marker>

        {/* Europe */}

        <Marker coordinates={[15, 54]} fill="#777">
          <g
            fill="none"
            stroke={answer && answer.includes('Europe') ? 'white' : '#FF5533'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="scale(0.5)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
        </Marker>

        {/* Asia */}

        <Marker coordinates={[90, 55]} fill="#777">
          <g
            fill="none"
            stroke={answer && answer.includes('Asia') ? 'white' : '#FF5533'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="scale(0.5)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
        </Marker>

        {/* Oceania */}

        <Marker coordinates={[130, -20]} fill="#777">
          <g
            fill="none"
            stroke={answer && answer.includes('Oceania') ? 'white' : '#FF5533'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="scale(0.5)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
        </Marker>
      </ComposableMap>
      <h4 className={classes.answer}>{`Select all that apply: ${
        answer && answer.length
      }`}</h4>
      <button
        type="button"
        className={classes.button}
        onClick={() => onChange('map')}
        disabled={!answer?.length}
      >
        Continue
      </button>
    </div>
  );
};

export default Map;
